<template>
  <div>
    <LoadingSpinner :is-loaded="problemLoaded">
      <ContentEditor
        :title="`${problem.number}. ${problem.name} - ${statement ? 'Zadanie' : 'Riešenie'}`"
        :alert="getAlert()"
        :initial="text"
        :model-id="problemId"
        :saving="saving"
        :upload-function="apiPostProblemAttachment"
        :delete-function="apiDelProblemAttachment"
        :reload-function="apiProblemAttachments"
        :info-function="apiHeadProblemAttachment"
        @save-text="save"
      >
        <b-row v-if="!statement">
          <b-col md="6">
            <FormulateInput
              v-model="videoSolution"
              label="Link na videovzorák"
              type="text"
            />
          </b-col>
        </b-row>
      </ContentEditor>
    </LoadingSpinner>
  </div>
</template>

<script>
import { apiProblems, apiRounds, LoadingSpinner } from "frontend-common";
import ContentEditor from "@/components/ContentEditor";

export default {
  name: "ProblemChange",
  components: {
    ContentEditor,
    LoadingSpinner,
  },
  mixins: [apiProblems, apiRounds],
  props: {
    problemId: {
      type: Number,
      required: true,
    },
    statement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      problemLoaded: false,
      taskPublished: false,
      solutionPublished: false,
      saving: false,
      problem: {},
      text: "",
      videoSolution: "",
    };
  },
  watch: {},
  mounted() {
    this.apiProblem(this.problemId)
      .then((response) => {
        this.problem = response;
        return this.apiRound(this.problem.round);
      })
      .then((response) => {
        this.solutionPublished = response.solutions_visible;
        this.taskPublished = response.visible;
      })
      .finally(() => {
        this.text = this.statement
          ? this.problem.statement
          : this.problem.solution;
        this.videoSolution = this.statement ? "" : this.problem.video_solution;
        this.problemLoaded = true;
      });
  },
  methods: {
    async save(newText) {
      this.saving = true;

      if (this.statement) {
        await this.apiUpdateProblemStatement(this.problemId, newText);
      } else {
        await this.apiUpdateProblemSolution(
          this.problemId,
          newText,
          this.videoSolution,
        );
      }

      this.$root.successToast(
        (this.statement ? "Zadanie" : "Riešenie") + " úspešne uložené!",
      );
      this.saving = false;
    },
    getAlert() {
      if (
        (this.statement && !this.taskPublished) ||
        (!this.statement && !this.solutionPublished)
      )
        return null;
      return `Pozor, upravuješ verejné ${this.statement ? "zadanie" : "riešenie"}.`;
    },
  },
};
</script>

<style scoped></style>
